import { List, Datagrid, TextField } from "react-admin";

export const OrganizationList = () => (
  <List resource="Organization">
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="externalId" label="External ID" />
      <TextField source="name" label="Name" />
      <TextField source="id" label="Internal ID" />
    </Datagrid>
  </List>
);
