import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
} from "react-admin";

export const OrganizationShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="externalId" label="External ID" />
      <TextField source="name" label="Name" />
      <TextField source="id" label="Inernal ID" />
      <ArrayField source="practitioners">
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField source="name" label="Name" />
          <TextField source="id" label="Caregiver ID" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="patients">
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField source="name" label="Name" />
          <TextField source="id" label="Internal ID" />
          <TextField source="source" label="Source" />
          <TextField source="fhirId" label="Fhir ID" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
